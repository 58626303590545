import { Controller } from "@hotwired/stimulus"
import posthog from "posthog-js"
import * as Sentry from '@sentry/browser'
export default class extends Controller {

  static values = {
    posthogKey: String,
    posthogHost: String,
    sentryDsn: String,
    identity: String
  };

  initialize() {
    // Bind event listener to ensure that the tracking is only initialized on full page load
    document.addEventListener("turbo:load", () => this.setupTracking());
  }

  setupTracking() {
    try {
      // Initialize PostHog if API key is provided
      if (this.hasPosthogKeyValue) {
        const mode = "always"; // or "identified_only"
        this.initializePosthog(mode);
      }
      // Initialize Sentry if DSN is provided
      const isSentryNotLoaded = typeof Sentry.getCurrentHub().getClient() == undefined;
      if (this.hasSentryDsnValue && isSentryNotLoaded) {
        this.initializeSentry();
      }
    } catch (error) {
      console.error("Error initializing tracking:", error);
      // Optionally, send this error to Sentry if it's initialized
      if (Sentry && Sentry.captureException) {
        Sentry.captureException(error);
      }
    }
  }

  initializePosthog(mode) {
    posthog.init(this.posthogKeyValue, {
      api_host: this.posthogHostValue,
      person_profiles: mode
    })

    posthog.capture("$pageview") // Track a page view
    if (this.hasIdentityValue) {
      posthog.identify(this.identityValue, {
        // Additional user properties can be added here
        // e.g., email: this.data.get("userEmail")
      });
    }
  }

  initializeSentry() {
    Sentry.init({
      dsn: this.sentryDsnValue,

      // Alternatively, use `process.env.npm_package_version` for a dynamic release version
      // if your build tool supports it.
      // release: "my-project-name@2.3.12",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
    if (this.hasIdentityValue) {
      Sentry.setUser({ id: this.identityValue });
      // You can add more user information if available
      // e.g., email: this.data.get("userEmail")
    }
  }
}
